export default class InfluenceTableItem {
    influenceId = 0;
    influenceStatus = 0;
    influenceParentId = 0;
    influenceParentName = "";
    influenceSortId = 0;
    influenceName = "";
    influenceRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
